import { autoserializeAs, autoserializeAsArray } from 'dcerialize';

import { AnnouncementTypeEnum } from '../definitions/announcement-type.enum';
import { environment } from '../environments/environment';
import { getStorageObject } from '../utils/storage-manager';
import { formatLanguage, SUPPORTED_LANGS } from '../utils/translate';

export class AnnouncementBody {
  /**
   * Spanish body
   */
  @autoserializeAs(() => String) es: string;

  /**
   * English body
   */
  @autoserializeAs(() => String) en: string;

  /**
   * Italian body
   */
  @autoserializeAs(() => String) it: string;

  /**
   * @param es
   * @param en
   * @param it
   */
  constructor(es: string, en: string, it: string) {
    this.es = es;
    this.en = en;
    this.it = it;
  }
}

export class Announcement {
  /**
   * ID
   */
  @autoserializeAs(() => Number) _id: number;

  /**
   * Announcement customers
   */
  @autoserializeAsArray(() => String) customers: string[];

  /**
   * Announcement type
   */
  @autoserializeAs(() => String) type: AnnouncementTypeEnum;

  /**
   * Announcement body
   */
  @autoserializeAs(() => AnnouncementBody) body: AnnouncementBody;

  /**
   * Announcement start date
   */
  @autoserializeAs(() => Date) startDate: Date;

  /**
   * Announcement end date
   */
  @autoserializeAs(() => Date) endDate: Date;

  /**
   * Whetever is active the announcement
   */
  @autoserializeAs(() => Boolean) isActive: boolean;

  /**
   *
   * @param _id
   * @param customers
   * @param type
   * @param body
   * @param startDate
   * @param endDate
   * @param isActive
   */

  constructor(
    _id: number,
    customers: string[],
    type: AnnouncementTypeEnum,
    body: AnnouncementBody,
    startDate: Date,
    endDate: Date,
    isActive: boolean
  ) {
    this._id = _id;
    this.customers = customers;
    this.type = type;
    this.body = body;
    this.startDate = startDate;
    this.endDate = endDate;
    this.isActive = isActive;
  }

  /**
   * Method to check if the announcement is active for user customer
   */
  isActiveForCustomer(customerDomain: string): boolean {
    return this.isActive && this.customers.includes(customerDomain);
  }

  /**
   * Method to extract the announcement body based on the user's language.
   */
  getAnnouncementBodyByLanguage(): string {
    const lang = getStorageObject(environment.currentUserLanguageStorage) || formatLanguage(navigator.language);
    const languageMap = {
      [SUPPORTED_LANGS.en]: this.body.en,
      [SUPPORTED_LANGS.es]: this.body.es,
      [SUPPORTED_LANGS.it]: this.body.it
    };

    return languageMap[lang];
  }
}
