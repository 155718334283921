export enum CompanyEnum {
  INESALUD = 'inesalud',
  INESEM = 'inesem',
  EDUCA = 'educa',
  EDUSPORT = 'edusport',
  ENALDE = 'enalde',
  ESIBE = 'esibe',
  INEAF = 'ineaf',
  INNOVA = 'innova',
  REDEDUCA = 'rededuca',
  EDUCAOPEN = 'educaopen',
  EUROINNOVA = 'euroinnova',
  EUROINNOVALEGACY = 'test',
  UNIVERSITY = 'app'
}
