import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Deserialize, IJsonObject } from 'dcerialize';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Announcement } from '../models/announcement';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class AnnouncementService {
  private http = inject(HttpClient);
  private apiService = inject(ApiService);

  path = '/announcement';

  constructor() {
    this.path = this.apiService.getSecurityUrl() + this.path;
  }

  /**
   * Get the announcement that is active
   */
  getAnnouncement(): Observable<Announcement> {
    return this.http
      .get<IJsonObject>(`${this.path}/current`)
      .pipe(map((announcement) => Deserialize(announcement, () => Announcement)));
  }
}
